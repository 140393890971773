import React, { useEffect, useRef } from "react";
import { Layout } from "../../layout";
import MainImage from "../../images/contactForm/contact-form-image.svg";
import Phone from "../../images/contactForm/phone.svg";
import PhoneGreen from "../../images/contactForm/phoneGreen.svg";
import Email from "../../images/contactForm/email.svg";
import EmailGreen from "../../images/contactForm/emailGreen.svg";
import { styled } from "../../../stitches.config";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useAppSelector } from "../../hooks/ReduxHooks";
import { Seo } from "../../components/shared/Seo";

export const ContactForm = () => {
  const { t } = useTranslation();
  const { primaryNumber, email } = useAppSelector((state) => state.contactInfo);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const script = document.createElement("script");
    if (ref && ref.current) {
      script.src = "https://ifbck.com/api/widget/9c1629963bed47c7/widget.js";
      script.async = true;
      script.defer = true;
      script.type = "application/javascript";
      ref.current?.appendChild(script);
    }
    return () => {
      ref.current?.removeChild(script);
    };
  }, []);

  return (
    <Layout>
      <PageContainer>
        <Seo title={t("contactForm.title")} image={MainImage} />
        <Container>
          <Image src={MainImage} />
          <Box>
            <PageTitle>{t("contactForm.title")}</PageTitle>
            <div>
              <ButtonsWrap>
                <ButtonTel href={`tel:${primaryNumber}`}>
                  {primaryNumber}
                </ButtonTel>
                <ButtonEmail href={`mailto:${email}`}>{email}</ButtonEmail>
              </ButtonsWrap>
              <FormTitle>{t("connectUsingFeedbackForm")}</FormTitle>
            </div>
          </Box>
        </Container>

        <StyledContainer
          ref={(interalRef) => (ref.current = interalRef)}
          className="elementor-widget-container "
        ></StyledContainer>
      </PageContainer>
    </Layout>
  );
};

const StyledContainer = styled("div", {
  width: "100%",
  padding: "0 16px 32px",
  margin: "0 auto",
  "@md": {
    maxWidth: 1280,
    padding: "32px 0px 64px",
  },
  "#iFrameWrapper-9c1629963bed47c7": {
    width: "100%",
    maxWidth: "unset",
    maxHeight: "unset",
    boxShadow: "none !important",
    height: "550px",
    margin: 0,
    iframe: {
      border: "none",
    },
    "@media (min-width: 802px)": {
      height: "460px",
    },
    "@md": {
      height: "450px",
    },
  },

  ".elementor-widget-container": {
    width: "100%",
  },
});

const Container = styled("div", {
  margin: "80px auto 0px",
  maxWidth: "1280px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "0 16px",
  "@md": {
    margin: "40px auto 12px",
    padding: 0,
    justifyContent: "space-between",
    flexDirection: "row-reverse",
  },
});

const Box = styled("div", {
  width: "100%",
  "@md": {
    maxWidth: "694px",
  },
});

const ButtonTel = styled("a", {
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textDecoration: "none",
  gap: 14,
  width: "100%",
  cursor: "pointer",
  padding: "14px",
  border: "none",
  borderRadius: 8,
  background: "$primaryBackground",
  color: "$text",
  fontWeight: 700,
  fontSize: "15px",
  lineHeight: "20px",
  transition: "all 500ms ease",
  "@md": {
    padding: "22px 50px",
    justifyContent: "flex-start",
  },
  "&:hover": {
    backgroundColor: "$accent",
    color: "$white",
    "&:before": {
      transition: "all 0.5s ease",
      background: `url(${Phone}) no-repeat`,
    },
  },
  "&:before": {
    content: "",
    position: "absolute",
    top: "37%",
    left: 20,
    width: 22,
    height: 22,
    background: `url(${PhoneGreen}) no-repeat`,
  },
});

const ButtonEmail = styled("a", {
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textDecoration: "none",
  gap: 14,
  width: "100%",
  cursor: "pointer",
  padding: "14px",
  border: "none",
  borderRadius: 8,
  background: "$primaryBackground",
  color: "$text",
  fontWeight: 700,
  fontSize: "15px",
  lineHeight: "20px",
  transition: "all 500ms ease",
  "@md": {
    padding: "22px 50px",
    justifyContent: "flex-start",
  },
  "&:hover": {
    backgroundColor: "$accent",
    color: "$white",
    "&:before": {
      transition: "all 0.5s ease",
      background: `url(${Email}) no-repeat`,
    },
  },
  "&:before": {
    content: "",
    position: "absolute",
    top: "37%",
    left: 20,
    width: 22,
    height: 17,
    background: `url(${EmailGreen}) no-repeat`,
  },
});

const Image = styled("img", {
  width: "90%",
  maxWidth: 402,
  marginBottom: 60,
  "@md": {
    margin: 0,
  },
});

const PageTitle = styled("h1", {
  margin: "0 0 32px",
  textAlign: "center",
  fontWeight: 700,
  fontSize: "32px",
  lineHeight: "40px",
  color: "$heading",
  "@md": {
    color: "$text",
    textAlign: "start",
    fontWeight: 400,
  },
});

const ButtonsWrap = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 14,
  marginBottom: 32,
  "@md": {
    marginBottom: 48,
    marginTop: 48,
    gap: 24,
    maxWidth: 350,
  },
});

const FormTitle = styled("h1", {
  margin: "0 0 32px",
  textAlign: "center",
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "28px",
  color: "$heading",
  padding: "0 16px",
  "@md": {
    margin: 0,
    padding: 0,
    color: "$text",
    textAlign: "start",
  },
});

const PageContainer = styled("div", {
  "@md": {
    maxWidth: 1280,
    margin: "0 auto",
  },
});
